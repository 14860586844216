
/******************************************
 * 하이쓰리디 - 클라이언트 모델
 ******************************************/

import {defineComponent, onMounted, ref, computed, watch} from "vue";
import {storeToRefs} from "pinia";
import {CommonStore} from "../store/common-store";
import {OptionsStore} from "../store/options-store";
import {EstimateStore} from "../store/estimate-store";
import {ModelStore} from "../store/model-store";
import {Model3D, GModalData, Process, DropItem} from "../js/interface";
import {AnalysisStatus, AnalysisStatusKo, Modals} from "../js/types";
import {
  binaryToArrayBuffer,
  reqCompletedUpload,
  reqCreateThumbnail,
  reqDeleteModel,
  reqStp2Stl,
  reqUploadModel,
} from "../js/upload";
import {numberToComma} from "../js/utils";

import tippy, {createSingleton} from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import Dropdown, {DropdownItem} from "../components/Dropdown.vue";
import Thumbnail from "../components/Thumbnail.vue";
import axios from "axios";
import {genStpThumbnail, genThumbnail} from "../js/three";
import {toast} from "vue3-toastify";

export default defineComponent({
  name: "ClientModel",
  components: {
    Thumbnail,
    Dropdown,
  },
  props: {
    model: {
      type: Object as () => Model3D,
      required: true,
    },
  },
  setup(props) {
    const {modalResult} = storeToRefs(CommonStore());
    const optionsStore = OptionsStore();
    const {processes, materials, optionCautionPaint} = storeToRefs(optionsStore);
    const estimateStore = EstimateStore();
    const {estimate, isEstimateSubmit} = storeToRefs(estimateStore);
    const modelStore = ModelStore();

    let currentPercent = ref(0);
    let modelCount = ref(1);
    let dropProcessTitle = ref("");
    let dropMaterialTitle = ref("");
    let isPaint = ref(false);

    watch(modalResult, (newV, oldV) => {
      if (props.model.id !== newV.result.modelId) {
        return;
      }

      switch (newV.modalName) {
        case Modals.ModelTrash:
          modelStore.removeModel(props.model.id);
          break;
      }
    });

    onMounted(async () => {
      if (optionCautionPaint.value && optionCautionPaint.value.length) {
        tippy(".info-paint", {
          content: `<pre>${optionCautionPaint.value}</pre>`,
          allowHTML: true,
        });
      }

      tippy(".info-qua", {
        content: `<pre>최적화 수량은 제작할때 빌드플레이트에 동시에 넣어서<br>동시제작이 가능한 최대수량이며 제작시 실 재료비만<br>추가되는 3D프린팅 최적화 수량입니다.</pre>`,
        allowHTML: true,
      });

      if (!props.model.isUploaded) {
        const arrayBuffer = await binaryToArrayBuffer(props.model.file);
        if (arrayBuffer) {
          await reqUploadModel(props.model, arrayBuffer, currentPercent);

          // 2024.08.20 밴파킹
          // 로콜에서 테스트시 주석처리
          const ext = props.model.name.slice(props.model.name.lastIndexOf(".") + 1).toLowerCase();
          if ("stp" === ext || "STP" === ext || "step" === ext || "STEP" === ext) {
            try {
              await reqStp2Stl(props.model);

              // signed Url 방식 적용
              let key = props.model.modelKey + ".stp.stl";
              axios
                .post(`/models/${props.model.id}`, {
                  key: key,
                })
                .then((r) => {
                  genStpThumbnail(props.model, r.data.result.signedUrl);
                });
            } catch (e) {
              console.error(e);
              toast.error(`${props.model.name} 업로드가 중지되었습니다.`);
              return;
            }
          } else {
            genThumbnail(props.model, props.model.file);
          }

          reqCompletedUpload(props.model);
        }
      }

      for (let m = 0; m < materials.value.length; m++) {
        const mate = materials.value[m];
        if (props.model.modelProcessId === mate.processId) {
          dropMaterialTitle.value = mate.name;
          break;
        }
      }

      return;
    });

    /**********************************************
     * Computed
     *********************************************/
    const classAnalysis = computed(() => {
      switch (props.model.modelAnalysis) {
        case AnalysisStatus.Fail:
          return "cs-btn-disabled";
        case AnalysisStatus.Warn:
          return "cs-btn-warn";
        case AnalysisStatus.Caution:
          return "cs-btn-caution";
        default:
          return "cs-btn-success";
      }
    });
    const totalAnalysis = computed(() => {
      if (AnalysisStatus.Processing === props.model.modelAnalysis) {
        return AnalysisStatusKo.Processing;
      }

      switch (props.model.modelAnalysis) {
        case AnalysisStatus.Fail:
          return AnalysisStatusKo.Fail;
        case AnalysisStatus.Warn:
          return AnalysisStatusKo.Warn;
        case AnalysisStatus.Caution:
          return AnalysisStatusKo.Caution;
        default:
          return AnalysisStatusKo.Pass;
      }
    });
    const dropProcess = computed(() => {
      const items: DropItem[] = [];

      processes.value.forEach((val) => {
        items.push({
          title: val.name,
          data: val,
        });
      });

      return items;
    });
    const dropMaterial = computed(() => {
      const items: DropItem[] = [];

      materials.value.forEach((val) => {
        if (props.model.modelProcessId === val.processId) {
          items.push({
            title: val.name,
            data: val,
          });
        }
      });

      return items;
    });

    /**********************************************
     * Event
     *********************************************/
    const onTrash = () => {
      const data: GModalData = {
        modalName: Modals.ModelTrash,
        isShowCancel: false,
        title: "확인",
        content: "파일을 삭제하시겠습니까?",
        result: {
          modelId: props.model.id,
        },
      };
      CommonStore().showGlobalModal(data);
    };
    const onChangePainting = () => {
      modelStore.setModelPaint(props.model.id, !isPaint.value);
      return;
    };
    const onModelCount = () => {
      modelStore.setModelCount(props.model.id, modelCount.value);
    };
    const onBlurModelCount = () => {
      if (!modelCount.value) {
        modelCount.value = 1;
      }
      modelStore.setModelCount(props.model.id, modelCount.value);
    };
    const onDropProcess = async (item: any) => {
      modelStore.setModelProcess(props.model.id, item.data.id);
      dropProcessTitle.value = item.data.name;

      for (let m = 0; m < materials.value.length; m++) {
        const mate = materials.value[m];
        if (props.model.modelProcessId === mate.processId) {
          dropMaterialTitle.value = mate.name;
          modelStore.setModelMaterial(props.model.id, mate.id);
          await modelStore.reqActiveModel(props.model);
          await modelStore.reqGetAnalysis(props.model.id, item.data);
          break;
        }
      }
    };
    const onDropMaterial = (item: any) => {
      modelStore.setModelMaterial(props.model.id, item.data.id);
      dropMaterialTitle.value = item.data.name;
      modelStore.reqActiveModel(props.model);
    };
    const onShowViewer = () => {
      modelStore.setCurrentModel(props.model);
    };

    return {
      currentPercent,
      modelCount,
      isEstimateSubmit,
      dropProcess,
      dropMaterial,
      dropProcessTitle,
      dropMaterialTitle,
      numberToComma,
      onChangePainting,
      onModelCount,
      onBlurModelCount,
      onTrash,
      onDropProcess,
      onDropMaterial,
      onShowViewer,
      classAnalysis,
      totalAnalysis,
    };
  },
});
